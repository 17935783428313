<template>
  <div>
    <div class="bigDiv">
      <div class="topImg">
        <img src="../../assets/images/foodList/foodXq.jpg" alt="" />
      </div>
      <div class="titles">
        <div>{{ this.foodInfo.name }}</div>
        <div class="ka">{{ this.energy[0].value}} / 100克</div>
      </div>
      <div class="info">
        <div class="tit">
          <div class="titA">能量和营养成分</div>
          <div class="titB">每100克可食用部分</div>
        </div>
        <div class="gailun">
          <div class="left">
            <van-circle v-model="currentRate" :rate="100" :stroke-width="40" size="100px" color="#1ac69d">
              <template #default>
                <div class="huan">
                  <div class="shuzi">{{reliang}}</div>
                  <div class="nengliang">千卡</div>
                </div>
              </template>
            </van-circle>
          </div>
          <div class="right">
            <div class="text" v-for="(item) in foodList" :key="item.key"><span class="block"></span> {{item.key}} : {{item.value.length>1? item.value : '---'}}</div>

          </div>
        </div>
      </div>
      <div class="list">
        <div class="listTit">
          <div>营养元素</div>
          <div>含量</div>
        </div>
        <div class="xian"></div>
        <div v-for="(item,index) in foodInfo.info " :key="item.key" v-show="index < 4">
          <div class="li">
            <div class="left">{{item.key}}</div>
            <div class="right">{{item.value}}</div>
          </div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="more" @click="moreList">更多营养素</div>
    </div>
  </div>
</template>

<script>
import { getWechatConfig } from "@/api/yangsheng";

export default {
  name: "",
  props: {},
  components: {},
  data () {
    return {
      currentRate: 100,
      foodInfo: {},
      energy: [],
      foodList: [],
      reliang: ''
    };
  },
  created () {
    this.foodInfo = JSON.parse(sessionStorage.getItem("foodInfo"));
    console.log(this.foodInfo.info, '8888');
    this.energy = this.foodInfo.info.filter(item => {
      console.log(item.key);
      return item.key === "热量(千卡)"
    },
    )
    console.log(this.energy, '8888');

    this.reliang = this.energy[0].value
    this.reliang = this.reliang.replace("千卡", "");
    const keysToFilter = ["蛋白质(克)", "脂肪(克)", "碳水化合物(克)"];
    this.foodList = this.foodInfo.info.filter(item => keysToFilter.includes(item.key));
  },
  methods: {
    moreList () {
      this.$router.push({
        path: "foodListAll",
      });
    },
    // 分享
    getWechatConfig () {

      const query = {
        company_id: 1,
        url: window.location.href
      }
      getWechatConfig(query).then(res => {
        const agent = res.data.data
        if (res.data.code === 200) {
          this.$nextTick(() => {

          })
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature,// 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData,'onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的JS接口列表
          });

          this.share()
        }

      }
      )
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: '食物搜索', // 分享标题
            link: 'https://kf.hshwhkj.com/foodList', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '营养食疗专业查询工具', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: '食物搜索', // 分享标题
            link: 'https://kf.hshwhkj.com/foodList', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '营养食疗专业查询工具', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          })

        });
        wx.error(res => {
          console.log('微信分享错误信息：', res)
        })
      })



    },

  },
  mounted () {
    this.getWechatConfig()
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
.bigDiv {
  overflow: hidden;
}
.topImg {
  width: 100%;
  z-index: -999;
  position: absolute;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.titles {
  // width: 350px;
  height: 60px;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 60px;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  div {
    min-width: 80px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.32);
  }
  .ka {
    font-size: 16px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.32);
    font-weight: 700;
  }
}
.info {
  margin: 0 auto;
  .tit {
    margin: 10px 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .titA {
      font-size: 15px;
      font-weight: 700;
    }
    .titB {
      font-size: 14px;
      margin-top: 1px;
      color: #999;
    }
  }
  .gailun {
    display: flex;
    .left {
      margin-top: 5px;
      flex: 1;
      text-align: center;
      .huan {
        margin-top: 20px;
      }
      .shuzi {
        font-size: 32px;
        font-weight: 700;
      }
      .nengliang {
      }
    }
    .right {
      font-size: 14px;
      flex: 1;
      text-align: left;
      margin-top: 5px;
      .text {
        margin: 10px 0;
        .block {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #1ac69d;
          margin: 0 5px;
        }
      }
    }
  }
}
.list {
  .listTit {
    color: #999;
    font-size: 14px;
    display: flex;
    width: 350px;
    margin: 10px auto;
    justify-content: space-between;
    margin-top: 30px;
  }
  .xian {
    width: 350px;
    height: 0.8px;
    margin: 10px auto;
    background-color: #f2f2f2;
  }
  .li {
    display: flex;
    width: 350px;
    justify-content: space-between;
    margin: 10px auto;
  }
}
.more {
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin: 30px auto;
  border-radius: 50px;
  background-color: #f8f8f8;
  color: #aba8a7;
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 650px;
    overflow: hidden;
    margin: 0 auto;
    .topImg {
      width: 650px;
      z-index: -999;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .titles {
      // width: 350px;
      height: 60px;
      text-align: center;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 180px;
      color: #fff;
      font-size: 42px;
      font-weight: 700;
      div {
        min-width: 80px;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.32);
      }
      .ka {
        font-size: 22px;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.32);
        font-weight: 700;
      }
    }
    .info {
      margin: 0 auto;
      .tit {
        margin: 10px 10px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .titA {
          font-size: 15px;
          font-weight: 700;
        }
        .titB {
          font-size: 14px;
          margin-top: 1px;
          color: #999;
        }
      }
      .gailun {
        display: flex;
        .left {
          margin-top: 5px;
          flex: 1;
          text-align: center;
          .huan {
            margin-top: 20px;
          }
          .shuzi {
            font-size: 32px;
            font-weight: 700;
          }
          .nengliang {
          }
        }
        .right {
          font-size: 14px;
          flex: 1;
          text-align: left;
          margin-top: 5px;
          .text {
            margin: 10px 0;
            .block {
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #1ac69d;
              margin: 0 5px;
            }
          }
        }
      }
    }
    .list {
      .listTit {
        color: #999;
        font-size: 14px;
        display: flex;
        width: 650px;
        margin: 10px auto;
        justify-content: space-between;
        margin-top: 30px;
      }
      .xian {
        width: 650px;
        height: 0.8px;
        margin: 10px auto;
        background-color: #f2f2f2;
      }
      .li {
        display: flex;
        width: 650px;
        justify-content: space-between;
        margin: 10px auto;
      }
    }
    .more {
      width: 120px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      margin: 30px auto;
      border-radius: 50px;
      background-color: #f8f8f8;
      color: #aba8a7;
    }
  }
}
</style>
